import PropTypes from "prop-types";
import React from 'react'
import {Helmet} from "react-helmet";

const SEO = ({title, desc}) => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" lang="en" />
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>

                <title>EXIGOH - Software Development Company</title>
                <meta name="title" content="EXIGOH - Software Development Company" />
                <meta name="description"
                      content="Leading software development company focused on quality. We develop complex websites, iOS/Android mobile applications and elaborate online business services." />
            </Helmet>
        </>
    )
}

SEO.propTypes = {
    // title: PropTypes.string
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
};

export default SEO;
