import AppFormularData from "../../data/appformular/AppFormular.json";
import React, {useState} from "react";
import SectionTitle from "../sectionTitle/SectionTitle";
import ScrollAnimation from "react-animate-on-scroll";
import "react-router-dom";
import FormularContactForm from "../contact/FormularContactForm";
import FormularAnswers from "../timeline/FormularAnswers";
import * as Trans from "../../data/lang/text";

const t = Trans.getText();


const AppFormular = () => {
    const allDataAppFormular = AppFormularData.sort();
    const [questionID, setQuestionID] = useState(0);
    const [answers] = useState([]);
    const [previosID] = useState([]);

    function changeQuestion(answer, prev) {
        if (answer.next === '/contact') {
            sessionStorage.setItem('subject', answer.answer);
            window.location = '/contact';
            return;
        }
        if (answer.next > 0) {
            previosID.push(prev);
        }
        saveAnswer(answer);
        setQuestionID(answer.next);
    }

    function saveAnswer(answer) {
        answer.question =  allDataAppFormular[questionID].question;
        answers.push(answer);
    }

    return questionID != null ? (<>
            <div className="col-lg-12">
                <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={false}>
                    <SectionTitle
                        textAlign="text-center"
                        title={allDataAppFormular[questionID].question}
                        description={allDataAppFormular[questionID].description}
                    />
                </ScrollAnimation>
                <div className="row row--15 justify-content-center">
                    {allDataAppFormular[questionID].answers.map((item) => (
                        <div onClick={() => changeQuestion(item, questionID)}
                             className="app-formular-item col-lg-3 col-md-4 col-12 mt--10">
                            <div className="rn-card box-card-style-default text-center">
                                <div>
                                    <img src={`${process.env.PUBLIC_URL}/${item.image}`} alt="Image"/>
                                    <div className="content">
                                        <h4 className="title">
                                            {item.answer}
                                        </h4>
                                        <p>{item.description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {
                    (questionID > 0) &&
                    <div className="button-group text-center col-12 mt--30">
                        <button onClick={() => setQuestionID(previosID.pop())}
                                className="btn-default btn-medium btn-border round">Back
                        </button>
                    </div>
                }

            </div>
        </>
    ) : (
        <>
            <div className="rwt-contact-area rn-section-gap">
                <div className="container">

                    <div className="row">
                        <div className="col-lg-12 mb--40">
                            <div className="text-center">
                                <h1 className="title theme-gradient h2">{t['contact']['title']} <br/> {t['contact']['title2']}</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row mt--40 row--15  justify-content-center">
                        <div className="col-lg-6 final-answers">
                            {/*<h4 className="title">Your Answers</h4>*/}
                            <FormularAnswers answers={answers} />
                        </div>
                        <div className="col-lg-6 mt--10 mt_md--30 mt_sm--30">
                            <FormularContactForm formStyle="contact-form-1" answers={answers}/>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default AppFormular;
