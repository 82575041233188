import React from 'react';
import Header from "./header/Header";
import Footer from "./footer/Footer";

const Layout = ({children, footer = true}) => {
    return footer ? (
        <>
            <script type="text/javascript" id="hs-script-loader" async defer src="//js-eu1.hs-scripts.com/25082527.js" />
            <main className="page-wrapper">
                <Header btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />
                {children}
                <Footer />
            </main>
        </>
    ) : (
        <>
            <script type="text/javascript" id="hs-script-loader" async defer src="//js-eu1.hs-scripts.com/25082527.js" />
            <main className="page-wrapper">
                <Header btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />
                {children}
            </main>
        </>
    )
}
export default Layout;
