import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'

// Pages import Here
import HomePage from "./pages/Home_page";
import AboutUsPage from "./pages/AboutUs_page";
import ContactPage from "./pages/ContactUs_page";
import Error from "./pages/Error";
import MobileAppPage from "./pages/MobileApp_page";
import WebsitePage from "./pages/Website_page";
import PortfolioDetailsPage from "./pages/PortfolioDetailsPage";
import BlogsPage from "./pages/Blogs_page";
import PrivacyPolicyPage from "./pages/PrivacyPolicy_page";
import TermsAndConditionPage from "./pages/TermsAndCondition_page";

// Import Css Here
import './assets/scss/style.scss';
import AppFomularPage from "./pages/AppFormular_page";
import * as Trans from './data/lang/text';

Trans.init();

export default function App() {
    return (
        <Router>
            <PageScrollTop>
                <Switch>
                    <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={HomePage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/about"}`} exact component={AboutUsPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/contact"}`} exact component={ContactPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/mobile-app"}`} exact component={MobileAppPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/website"}`} exact component={WebsitePage}/>

                    <Route path={`${process.env.PUBLIC_URL + "/start"}`} exact component={AppFomularPage}/>

                    {/* <Route path={`${process.env.PUBLIC_URL + "/blog"}`} exact component={BlogsPage}/> */}

                    <Route path={`${process.env.PUBLIC_URL + "/portfolio/:id"}`} exact component={PortfolioDetailsPage}/>

                    <Route path={`${process.env.PUBLIC_URL + "/privacy-policy"}`} exact component={PrivacyPolicyPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/terms-and-condition"}`} exact component={TermsAndConditionPage}/>

                    <Route  path={`${process.env.PUBLIC_URL + "/error"}`} exact component={Error}/>
                    <Route path='*' exact component={Error}/>
                </Switch>
            </PageScrollTop>
        </Router>
    )
}
