import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';
import BlogListTwo from "../../components/blog/itemProp/BlogListTwo";

const AccordionOne = ({customStyle, data}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            {data.map((item, index) => (
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey={`${index}`}>
                            {item.q}
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={`${index}`}>
                        <Card.Body>{item.a} </Card.Body>
                    </Accordion.Collapse>
                </Card>
            ))}



        </Accordion>
    )
}
export default AccordionOne

