import React, {useState} from 'react';
import emailjs from 'emailjs-com';
import * as Trans from "../../data/lang/text";

const t = Trans.getText();

const Result = () => {
    return (
        <p className="success-message">{t['contact']['send']}</p>
    )
}

function ContactForm({props, formStyle}) {
    const [result, showresult] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(
                'service_oyvik4h',
                'template_nccd9qt',
                e.target,
                'user_Yda6NVQbQtOgAqg5PbFDQ'
            )
            .then((result) => {
                    console.log('SUCCESS!', result.status, result.text);
                },
                (error) => {
                    console.log('FAILED...', error);
                }
            );
        e.target.reset();
        showresult(true);
    };

    function checkSubject() {
        let subject = sessionStorage.getItem('subject');
        if (subject) {
           return subject;
        }
        return null;
    }

    setTimeout(() => {
        showresult(false);
    }, 5000);


    return (
        <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
            <div className="form-group">
                <input
                    type="text"
                    name="fullname"
                    placeholder={t['contact']['name']}
                    required
                />
            </div>

            <div className="form-group">
                <input
                    type="email"
                    name="email"
                    placeholder={t['contact']['your_email']}
                    required
                />
            </div>

            <div className="form-group">
                <input
                    type="text"
                    name="phone"
                    placeholder={t['contact']['your_phone']}
                    required
                />
            </div>


            <div className="form-group">
                <input
                    type="text"
                    name="subject"
                    placeholder={t['contact']['subject']}
                    value={checkSubject()}
                    required
                />
            </div>

            <div className="form-group">
                <textarea
                    name="message"
                    placeholder={t['contact']['message']}
                    required
                >
                </textarea>
            </div>

            <div className="form-group">
                <button className="btn-default btn-large">{t['contact']['submit']}</button>
            </div>

            <div className="form-group">
                {result ? <Result/> : null}
            </div>
        </form>
    )
}

export default ContactForm;
