import React from 'react'
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import BrandThree from "../elements/brand/BrandThree";
import Separator from "../elements/separator/Separator";
import MultiAward from "../elements/about/MultiAward";
import SlipThree from "../elements/split/SlipThree";
import * as Trans from "../data/lang/text";


const AboutUsPage = () => {
    const t = Trans.getText();
    return (
        <>
            <SEO title="About Us"/>
            <Layout>
                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-850 bg_image" data-black-overlay="7"
                     style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/about-bg.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">{t['about']['subtitle']}</span>
                                        </h3>
                                    </div>
                                    <h1 className="title display-one">{t['about']['title']} <br/> {t['about']['title2']}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}


                {/* Start Service Area  */}
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">{t['about']['s1_1']}</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">{t['about']['s1_2']}</p>
                                <p>{t['about']['s1_3']}</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}

                {/* Start Brand Area  */}
                {/* <div className="rwt-brand-area pb--60 pt--30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                                <BrandThree brandStyle="brand-style-2"/>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Brand Area  */}

                <Separator/>

                {/* Start Award Section */}
                {/* <MultiAward/> */}
                {/* End Award Section */}

                <SlipThree/>

            </Layout>
        </>
    )
}

export default AboutUsPage;
