import Layout from "../common/Layout";
import SEO from "../common/SEO";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import React from "react";
import SlpitOne from "../elements/split/SlpitOne";
import Separator from "../elements/separator/Separator";
// import TestimonialTwo from "../elements/testimonial/TestimonialTwo";
import AccordionOne from "../elements/accordion/AccordionOne";
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";
import * as Trans from "../data/lang/text";

const t = Trans.getText();

const AccordianData = [
    {
        "q": t['website']['q1'],
        "a": t['website']['a1'],
    },
    {
        "q": t['website']['q2'],
        "a": t['website']['a2'],
    },
]

const WebsitePage = () => {
    return (
        <>
            <SEO title="Website" desc=""/>
            <Layout>
                <div className="rwt-advance-tab-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle={t['website']['subtitle']}
                                    title={t['website']['title']}
                                    description={t['website']['description']}
                                />
                            </div>
                        </div>
                        <SlpitOne/>
                    </div>
                </div>

                {/* <div className="rwt-advance-tab-area rn-section-gap">
                    <div className="container">
                        <TestimonialTwo/>
                    </div>
                </div> */}
                <div className="rwt-advance-tab-area rn-section-gap">
                    <div className="container">
                        <CalltoActionFive text={t['website']['s2_1']}/>
                    </div>
                </div>

                <Separator/>


                <div className="rwt-advance-tab-area rn-section-gap">
                    <div className="container">
                        <AccordionOne data={AccordianData}/>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default WebsitePage;
