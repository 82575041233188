import React from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import * as Trans from "../../data/lang/text";

const t = Trans.getText();

const ServiceList = [
    {
        image: '/images/service/mobile-app.svg',
        title: t["home"]["mobile"],
        link: '/mobile-app',
        description: t["home"]["mobile_description"]
    },
    {
        image: '/images/service/web-devices.svg',
        title: t["global"]["website"],
        link: '/website',
        description: t["home"]["website_description"]
    },
]
const ServiceTwo = ({textAlign, cardStyle}) => {
    return (
        <div className="row row--15 service-wrapper center">
            {ServiceList.map((val, i) => (
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 center" key={i}>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                        <div className={`card-box ${cardStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="image">
                                    <Link to={`${process.env.PUBLIC_URL + val.link}`}>
                                        <img src={`${val.image}`} alt="card Images" className="service-img"/>
                                    </Link>
                                </div>
                                <div className="content">
                                    <h4 className="title mb--20">
                                        <Link to={`${process.env.PUBLIC_URL + val.link}`}
                                              dangerouslySetInnerHTML={{__html: val.title}}/>
                                    </h4>
                                    <p className="description b1 color-gray mb--0"
                                       dangerouslySetInnerHTML={{__html: val.description}}/>
                                    <Link className="btn-default btn-small btn-border"
                                          to={`${process.env.PUBLIC_URL + val.link}`}>{t["global"]["read_more"]}</Link>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceTwo;
