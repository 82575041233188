import React from 'react';
import ScrollTop from "./ScrollTop";
import Copyright from "./Copyright";

const Footer = () => {
    return (
        <>
            <Copyright/>
            <ScrollTop/>
        </>
    )
}

export default Footer;
