import SEO from "../common/SEO";
import React from "react";
import AppFormular from "../elements/AppFormular/AppFormular";
import Layout from "../common/Layout";

const AppFomularPage = () => {
    return (
        <>
            {/*<main className="page-wrapper">*/}
            <Layout footer={false}>
                <SEO title="App Formular" desc="EXIGOH - Software Development Company"/>

                <div className="main-content">
                    <AppFormular/>
                </div>

            </Layout>


            {/*</main>*/}
        </>
    )
}

export default AppFomularPage;
