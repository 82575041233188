import React from 'react'
import {FiFacebook, FiInstagram} from "react-icons/fi";
import {Link} from "react-router-dom";

const Copyright = () => {
    return (
        <div className="copyright-area copyright-style-one">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                        <div className="copyright-left">
                            <ul className="ft-menu link-hover">
                                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                <li><Link to="/terms-and-condition">Terms And Condition</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 mt_sm--20">
                        <div className="copyright-center text-center">
                            <ul className="social-icon social-default color-dark with-gradient justify-content-center">
                                <li><a target="_blank" href="https://facebook.com/exigoh" rel="noreferrer"><FiFacebook/></a>
                                </li>
                                <li><a target="_blank" href="https://instagram.com/exigoh_com"
                                       rel="noreferrer"><FiInstagram/></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt_md--20 mt_sm--20">
                        <div className="copyright-right text-center text-md-right">
                            <p className="copyright-text">© EXIGOH {new Date().getFullYear()}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Copyright;
