import React from 'react';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import ContactOne from "../elements/contact/ContactOne";
import * as Trans from "../data/lang/text";

const ContactPage = () => {
    const t = Trans.getText();
    return (
        <>
            <SEO title="Contact" desc="EXIGOH - Contact us now" />
            <Layout>
                <div className="main-content">
                    <div className="rwt-contact-area rn-section-gap">

                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <div className="text-center">
                                        <h1 className="title theme-gradient h2">{t['contact']['title']} <br /> {t['contact']['title2']}</h1>
                                    </div>
                                </div>
                            </div>

                            <ContactOne />
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default ContactPage;
