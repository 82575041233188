import React from 'react';

const FormularAnswers = ({answers}) => {
    return (
        <div className="rn-timeline-wrapper timeline-style-one position-relative">
            {answers.map((data, index) => (
                <div className="single-timeline mt--10 app-formular-timeline-item" key={index}>
                    <div className="single-content">
                        <div className="inner">
                            <div className="row align-items-center">
                                <div className="order-2 order-1 col-2">
                                    <div className="thumbnail">
                                        <img src={`./${data.image}`} alt=""/>
                                    </div>
                                </div>
                                <div className="order-1 order-2 col-10">
                                    <div className="content">
                                        <span className="date-of-timeline">{data.question}</span>
                                    </div>
                                    <div className="content">
                                        <span className="title">{data.answer}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default FormularAnswers;
