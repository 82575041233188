import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import * as Trans from "../../data/lang/text";

const SlipFour = () => {
    const t = Trans.getText();

    return (
        <div className="rn-splite-style bg-color-blackest">
            <div className="split-wrapper">
                <div className="row no-gutters radius-10 align-items-center">
                    <div className="order-2 order-lg-1 col-lg-12 col-xl-6 col-12">
                        <div className="split-inner">
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <h4 className="title">{t['mobile']['s1_1']}</h4>
                            </ScrollAnimation>
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <p className="description">{t['mobile']['s1_2']}</p>
                            </ScrollAnimation>
                            <p>{t['mobile']['s1_3']}</p>
                        </div>
                    </div>
                    <div className="order-1 order-lg-2 col-lg-12 col-xl-5 col-12">
                        <div className="thumbnail">
                            <img src="./images/service/mobile-application.svg" alt="split Images" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SlipFour;
