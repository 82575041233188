import React from 'react';
import {Link} from "react-router-dom";
import * as Trans from "../../data/lang/text";

const Nav = () => {
    const t = Trans.getText();
    return (
        <ul className="mainmenu">
            <li><Link to="/">{t['nav']['home']}</Link></li>
            <li><Link to="/about">{t['nav']['about']}</Link></li>

            <li className="has-droupdown"><Link to="#">{t['nav']['services']}</Link>
                <ul className="submenu">
                    <li><Link to="/mobile-app">{t['nav']['mobile']}</Link></li>
                    <li><Link to="/website">{t['nav']['website']}</Link></li>
                </ul>
            </li>

            <li><Link to={{ pathname: "https://blog.exigoh.com" }} target="_blank">{t['nav']['blog']}</Link></li>
            <li><Link to="/contact">{t['nav']['contact']}</Link></li>
        </ul>
    )
}
export default Nav;
