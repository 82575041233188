export const init = () => {
    let userLang = navigator.language || navigator.userLanguage;

    const lang = localStorage.getItem('lang');
    if (lang) {
        setLang(lang);
    } else {
        localStorage.setItem("lang", userLang);
    }
}

export const getText = () => {
    switch (localStorage.getItem("lang")) {
        case 'de-DE':
            return lang_de[0];
        default: {
            return lang_en[0];
        }
    }
}

function setLang(lang) {
    localStorage.setItem("lang", lang);
}

const lang_en = [
    {
        nav: {
            home: "Home",
            about: "About",
            services: "Services",
            mobile: "Mobile Application",
            website: "Website",
            blog: "Blog",
            contact: "Contact",
            contact_btn: "START NOW"
        },
        global: {
            all: "All",
            mobile: "Mobile App",
            website: "Website",
            contact: "Contact Us",
            read_more: "Read More",
            load_more: "Load More",
            service: "Service"
        },
        home: {
            subtitle: "We help our clients succeed by creating digital experiences with websites or mobile apps.",
            s1_1: "What We Can Do For You",
            s1_2: "Services provide for you.",
            s1_3: "Each of our projects is made up of the efficient cooperation of several specialists.",
            mobile: "Mobile Application",
            mobile_description: "Whether looking to create a mobile application for smartphones, tablets or both, we cover your organization no matter the platform it is to be built on or device it is to be used in conjunction with.",
            website_description: "We create powerful and scalable web applications that are customised with the help of agile development processes using the latest web technologies.",
            portfolio: "Portfolio",
            portfolio_title: "You can build everything!",
            no_more: "No Item Here"
        },
        about: {
            title: "We are a Software",
            title2: "Development Company.",
            subtitle: "Our Company's About Details.",
            s1_1: "We are a software development company which work on your future.",
            s1_2: "We help build successful digital products for every imaginable client around the world. In our process we always focus on quality, testing and want to deliver good software on time.\n" +
                "\n" +
                "We examine your needs – from the analysis, through the development. Regardless of whether you want to start a new project or scale an existing one, we know what to do and we want to help you bring your vision to life.",
            reward: "We have completed 60+ project.",
            reward2: "Have More Projects Complete in History.",
            s2_1: "Continue Your Business With Us.",
            s2_2: "In times of digitalisation, globalisation and international market economy, it is increasingly important for companies to be represented competently and properly on the internet. The goal is to have a good \"online presence\", which means not only a neat, beautiful and functional website but also any form of visibility in digital media. We at EXIGOH want to help you do just that.",
        },
        mobile: {
            title: "Mobile Application Development",
            s1_1: "Professional and affordable!",
            s1_2: "Only effective mobile applications with high-quality design and thoughtful interface. For smartphones and tablets.",
            s1_3: "Regardless of which phase your company is in, one thing is particularly relevant to you: you want professional app development that is also affordable.",
            s2_1: "This is how your APP is made",
            s2_2: "This is how we work as an software company to deliver your app of the highest quality.",
            step: "Step",
            step1_title: "YOUR IDEA",
            step1_desc: "The first step has already been taken with an app idea. Now you want to depict your mobile vision in an app? We will show you how we can develop your idea into an app that can do more than just look good!",
            step2_title: "CONCEPT",
            step2_desc: "To ensure the sustainable success of mobile app development, it is elementary that we understand not only the product but also the long-term corporate vision behind it. We develop a customer-oriented solution in discussions, workshops and intensive brainstorming sessions.",
            step3_title: "DESIGN",
            step3_desc: "In close consultation with you, the design phase begins, where we work with you to create the app screens and build them into a click dummy that defines the look and feel of your app. The central aspect of our UX/UI design is an appealing look and intuitive usability. After your approval of the app designs, we will proceed with the further app development.",
            step4_title: "DEVELOPMENT",
            step4_desc: "During the agile implementation in sprints in the development of your app, you will be informed about the current progress and your feedback will be constantly taken into account in the Scrum meetings and integrated in subsequent sprints in the app development.",
            step5_title: "SUPPORT",
            step5_desc: "With the release, the app development is not yet completely finished. Apps must always be kept up to date and require regular updates. We therefore continue to be your contact after acceptance and launch of your app and offer you the usual service via support contracts.",
            q1: "How much sost the developing of a mobile app?",
            a1: "Prices depend on the complexity of your requirements. Contact us and receive a non-binding offer from us after a detailed consultation.",
            q2: "How long does it take to realise a project?",
            a2: "The duration depends on the requirements. But usually the realisation with us takes a maximum of three months.",
            s3_1: "Ready to start creating a mobile application?",
            s3_2: "Do not hesitate to contact us",
            s3_3: "START NOW",
        },
        website: {
            title: "Website Development",
            subtitle: "Service",
            description: "We create some websites",
            testimonial_desc: "Our wishes were fulfilled with flying colours. We can only be happy to work with EXIGOH.",
            s1_1: "Responsive-Web Development",
            s1_2: "Our IT specialists will programme a website for you according to your ideas. In this way, even wishes such as an automated system for sales processing can be realised. A creative, individual website with appropriate keywords is the first step to building up a customer base on the internet.",
            s1_3: [
                "The way to a convincing website:",
                "Analysis and strategy development",
                "Conception and wireframing",
                "Development",
                "Editorial and technical support",
                "Further development and expansion"
            ],
            s1_4: "Contact With Us",
            s2_1: "Ready to start creating a website?",
            q1: "How much sost the developing of a website?",
            a1: "Prices depend on the complexity of your requirements. Contact us and receive a non-binding offer from us after a detailed consultation.",
            q2: "How long does it take to realise a project?",
            a2: "The duration depends on the requirements. But usually the realisation with us takes a maximum of two months."
        },
        contact: {
            title: "The Easiest Way To Start",
            title2: "fill in & send.",
            name: "Your Name",
            our_phone: "Contact Phone Number",
            your_phone: "Phone Number",
            our_email: "Our Email Address",
            your_email: "Email Address",
            your_website: "Website",
            location: "Our Location",
            subject: "Subject",
            message: "Your Message",
            submit: "Submit Now",
            send: "Your Message has been successfully sent. We will contact you soon."
        },
        blog: {
            title: "We blog sometimes!",
            load_more: "View More Post",
            no_post: "No more Post"
        }
    }
]

const lang_de = [
    {
        nav: {
            home: "Startseite",
            about: "Über uns",
            services: "Dienstleistungen",
            mobile: "Mobile Anwendung",
            website: "Webseite",
            blog: "Blog",
            contact: "Kontakt",
            contact_btn: "STARTEN"
        },
        global: {
            all: "Alle",
            mobile: "Mobile App",
            website: "Webseite",
            contact: "Kontaktiere uns",
            read_more: "Jetzt mehr lesen",
            load_more: "Mehr laden",
            service: "Dienstleistung"
        },
        home: {
            subtitle: "Wir verhelfen unseren Kunden zum Erfolg, indem wir digitale Erlebnisse mit Websites oder mobilen Anwendungen schaffen.",
            s1_1: "Was wir für Sie tun können",
            s1_2: "Dienstleistungen für Sie bereitstellen.",
            s1_3: "Jedes unserer Projekte setzt sich aus der effizienten Zusammenarbeit mehrerer Spezialisten zusammen.",
            mobile: "Mobile Anwendung",
            mobile_description: "Ganz gleich, ob Sie eine mobile Anwendung für Smartphones, Tablets oder beides erstellen möchten, wir decken Ihr Unternehmen ab. Wir machen es für Sie möglich.",
            website_description: "Wir erstellen leistungsstarke und skalierbare Webanwendungen, die mit Hilfe von agilen Entwicklungsprozessen unter Verwendung der neuesten Webtechnologien angepasst werden.",
            portfolio: "Portfolio",
            portfolio_title: "Du kannst alles bauen!",
            no_more: "Keine Weitere hier"
        },
        about: {
            title: "Wir sind ein Software",
            title2: "Entwicklungsgesellschaft.",
            subtitle: "Informationen über unser Unternehmen.",
            s1_1: "Wir sind ein Unternehmen für Softwareentwicklung, das an Ihrer Zukunft arbeitet.",
            s1_2: "Wir helfen bei der Entwicklung erfolgreicher digitaler Produkte für jeden erdenklichen Kunden auf der ganzen Welt. In unserem Prozess konzentrieren wir uns immer auf Qualität, Testen und wollen gute Software pünktlich liefern.",
            s1_3: "Wir untersuchen Ihre Bedürfnisse - von der Analyse bis zur Entwicklung. Unabhängig davon, ob Sie ein neues Projekt starten oder ein bestehendes skalieren wollen, wir wissen, was zu tun ist, und wir wollen Ihnen helfen, Ihre Vision zum Leben zu erwecken.",
            reward: "Wir haben über 68 Projekte abgeschlossen.",
            reward2: "Haben in der Vergangenheit mehrere Projekte abgeschlossen.",
            s2_1: "Setzen Sie Ihr Geschäft mit uns fort.",
            s2_2: "In Zeiten von Digitalisierung, Globalisierung und internationaler Marktwirtschaft wird es für Unternehmen immer wichtiger, kompetent und angemessen im Internet vertreten zu sein. Das Ziel ist eine gute \"Online-Präsenz\", was nicht nur eine ordentliche, schöne und funktionale Website bedeutet, sondern auch jede Form der Sichtbarkeit in digitalen Medien. Wir von EXIGOH wollen Ihnen dabei helfen.",
        },
        mobile: {
            title: "Entwicklung mobiler Anwendungen",
            s1_1: "Professionell und erschwinglich!",
            s1_2: "Nur effektive mobile Anwendungen mit hochwertigem Design und durchdachter Schnittstelle. Für Smartphones und Tablets.",
            s1_3: "Unabhängig davon, in welcher Phase sich Ihr Unternehmen befindet, ist eines für Sie besonders relevant: Sie wollen eine professionelle App-Entwicklung, die auch bezahlbar ist.",
            s2_1: "So wird Ihre APP hergestellt",
            s2_2: "So arbeiten wir als Softwareunternehmen, um Ihre App in höchster Qualität zu liefern.",
            step: "Stufe",
            step1_title: "IHRE IDEE",
            step1_desc: "Der erste Schritt ist mit einer App-Idee bereits getan. Jetzt wollen Sie Ihre mobile Vision in einer App abbilden? Wir zeigen Ihnen, wie wir Ihre Idee zu einer App entwickeln, die mehr kann als nur gut aussehen!",
            step2_title: "KONZEPT",
            step2_desc: "Für den nachhaltigen Erfolg der mobilen App-Entwicklung ist es elementar, dass wir nicht nur das Produkt, sondern auch die langfristige Unternehmensvision dahinter verstehen. In Gesprächen, Workshops und intensiven Brainstorming-Sessions entwickeln wir eine kundenorientierte Lösung.",
            step3_title: "DESIGN",
            step3_desc: "In enger Absprache mit Ihnen beginnt die Designphase, in der wir gemeinsam mit Ihnen die App-Screens entwerfen und zu einem Klick-Dummy zusammensetzen, der das Look & Feel Ihrer App definiert. Der zentrale Aspekt unseres UX/UI-Designs ist eine ansprechende Optik und intuitive Bedienbarkeit. Nach Ihrer Freigabe des App-Designs fahren wir mit der weiteren App-Entwicklung fort.",
            step4_title: "ENTWICKLUNG",
            step4_desc: "Während der agilen Umsetzung in Sprints in der Entwicklung Ihrer App werden Sie über den aktuellen Fortschritt informiert und Ihr Feedback wird laufend in den Scrum-Meetings berücksichtigt und in den folgenden Sprints in die App-Entwicklung integriert.",
            step5_title: "SUPPORT",
            step5_desc: "Mit der Veröffentlichung ist die App-Entwicklung noch nicht ganz abgeschlossen. Apps müssen immer auf dem neuesten Stand gehalten werden und benötigen regelmäßige Updates. Deshalb sind wir auch nach der Abnahme und dem Launch Ihrer App weiterhin Ihr Ansprechpartner und bieten Ihnen den gewohnten Service über Supportverträge.",
            q1: "Wie hoch ist der Preis für die Entwicklung einer mobilen App?",
            a1: "Die Preise richten sich nach der Komplexität Ihrer Anforderungen. Kontaktieren Sie uns und erhalten Sie nach einer ausführlichen Beratung ein unverbindliches Angebot.",
            q2: "Wie lange dauert es, ein Projekt zu realisieren?",
            a2: "Die Dauer hängt von den Anforderungen ab. Aber in der Regel dauert die Realisierung bei uns maximal drei Monate.",
            s3_1: "Sind Sie bereit, mit der Erstellung einer mobilen Anwendung zu beginnen?",
            s3_2: "Zögern Sie nicht damit zu starten",
            s3_3: "START",
        },
        website: {
            title: "Website-Entwicklung",
            subtitle: "Dienstleistung",
            description: "Wir erstellen einige Webseiten",
            testimonial_desc: "Unsere Wünsche wurden mit Bravour erfüllt. Wir können nur glücklich sein, mit EXIGOH zu arbeiten.",
            s1_1: "Responsive-Web-Entwicklung",
            s1_2: "Unsere IT-Spezialisten programmieren für Sie eine Website nach Ihren Vorstellungen. So lassen sich auch Wünsche wie z.B. ein automatisiertes System zur Verkaufsabwicklung realisieren. Eine kreative, individuelle Website mit passenden Keywords ist der erste Schritt zum Aufbau eines Kundenstamms im Internet.",
            s1_3: [
                "Der Weg zu einer überzeugenden Website:",
                "Analyse und Strategieentwicklung",
                "Konzeption und Wireframing",
                "Entwicklung",
                "Redaktionelle und technische Unterstützung",
                "Weitere Entwicklung und Expansion"
            ],
            s1_4: "Mit uns Kontakt aufnehmen",
            s2_1: "Sind Sie bereit, mit der Erstellung einer Website zu beginnen?",
            q1: "Wie hoch ist der Preis für die Entwicklung einer Website?",
            a1: "Die Preise richten sich nach der Komplexität Ihrer Anforderungen. Kontaktieren Sie uns und erhalten Sie nach einer ausführlichen Beratung ein unverbindliches Angebot.",
            q2: "Wie lange dauert es, ein Projekt zu realisieren?",
            a2: "Die Dauer hängt von den Anforderungen ab. Aber in der Regel dauert die Realisierung bei uns maximal zwei Monate."
        },
        contact: {
            title: "Der einfachste Weg zum Start",
            title2: "ausfüllen & abschicken.",
            name: "Ihr Name",
            our_phone: "Telefonnummer",
            your_phone: "Telefonnummer",
            our_email: "Unsere E-Mail Adresse",
            your_email: "E-Mail Adresse",
            your_website: "Webseite",
            location: "Unser Standort",
            subject: "Betreff",
            message: "Ihre Nachricht",
            submit: "Jetzt abschicken",
            send: "Ihre Nachricht wurde erfolgreich gesendet. Wir werden Sie bald kontaktieren."
        },
        blog: {
            title: "Wir bloggen manchmal!",
            load_more: "Mehr anzeigen",
            no_post: "Keine weitere Blogs"
        }
    }
];

