import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import * as Trans from "../../data/lang/text";
const t = Trans.getText();

const TimelineData = [
    {
        id: "1",
        date: t['mobile']['step']+"-1",
        title: t['mobile']['step1_title'],
        description: t['mobile']['step1_desc'],
        image: "idea",
    },
    {
        id: "2",
        date: t['mobile']['step']+"-2",
        title: t['mobile']['step2_title'],
        description: t['mobile']['step2_desc'],
        image: "concept",
    },
    {
        id: "3",
        date: t['mobile']['step']+"-3",
        title: t['mobile']['step3_title'],
        description: t['mobile']['step3_desc'],
        image: "design",
    },
    {
        id: "4",
        date: t['mobile']['step']+"-4",
        title: t['mobile']['step4_title'],
        description: t['mobile']['step4_desc'],
        image: "development",
    },
    {
        id: "5",
        date: t['mobile']['step']+"-5",
        title: t['mobile']['step5_title'],
        description: t['mobile']['step5_desc'],
        image: "support",
    },
]

const TimelineOne = () => {
    return (
        <div className="rn-timeline-wrapper timeline-style-one position-relative">
            <div className="timeline-line"></div>
            {TimelineData.map((data, index) =>(
                <div className="single-timeline mt--50" key={index}>
                    <div className="timeline-dot">
                        <div className="time-line-circle"></div>
                    </div>
                    <div className="single-content">
                        <div className="inner">
                            <div className="row row--30 align-items-center">
                                <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                    <div className="content">
                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <span className="date-of-timeline">{data.date}</span>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <h2 className="title">{data.title}</h2>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <p className="description">{data.description}</p>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                                <div className="order-1 order-lg-2 col-lg-6">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./images/service/${data.image}.svg`} alt="Corporate React Template" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
           
            {/* End Single Timeline  */}
        </div>
    )
}

export default TimelineOne;
