import Layout from "../common/Layout";
import SEO from "../common/SEO";
import React from "react";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import AccordionOne from "../elements/accordion/AccordionOne";
import Separator from "../elements/separator/Separator";
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";
import SlipFour from "../elements/split/SlipFour";
import TimelineOne from "../elements/timeline/TimelineOne";
import * as Trans from "../data/lang/text";

const t = Trans.getText();

const AccordianData = [
    {
        "q": t['mobile']['q1'],
        "a": t['mobile']['a1']
    },
    {
        "q": t['mobile']['q2'],
        "a": t['mobile']['a2']
    },
]

const MobileAppPage = () => {
    return (
        <>
            <SEO title="Mobile App" desc=""/>
            <Layout>
                <div className="rwt-advance-tab-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    subtitle={t['global']['service']}
                                    title={t['mobile']['title']}
                                />
                            </div>
                        </div>
                        <SlipFour/>
                    </div>
                </div>

                <Separator/>
                <div className="rwt-advance-tab-area rn-section-gap">
                    <div className="container">
                        <SectionTitle
                            textAlign="text-center"
                            secondtitle={t['mobile']['s2_1']}
                            description={t['mobile']['s2_2']}
                        />
                        <TimelineOne/>
                    </div>
                </div>

                <Separator/>

                <CalltoActionFive text={t['mobile']['s3_1']}/>

                <div className="rwt-advance-tab-area rn-section-gap">
                    <div className="container">
                        <AccordionOne data={AccordianData}/>
                    </div>
                </div>

            </Layout>
        </>
    )
}

export default MobileAppPage;
