import React from 'react';
import ContactForm from "./ContactForm";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
// import GoogleMapStyle from "./GoogleMapStyle";
import * as Trans from "../../data/lang/text";

const ContactOne = () => {
    const t = Trans.getText();

    return (
        <>
            <div className="row mt--40 row--15">
                <div className="col-lg-7">
                    <ContactForm formStyle="contact-form-1" />
                </div>
                <div className="col-lg-5 mt_md--30 mt_sm--30">
                    <img src="./images/contact/contact.svg" alt="Contact" />
                    {/*<GoogleMapStyle />*/}
                </div>
            </div>

            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">{t['contact']['our_phone']}</h4>
                                        <p><a href="tel:+49 871 20658161">+49 176 43496217</a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">{t['contact']['our_email']}</h4>
                                        <p><a href="mailto:contact@exigoh.com">contact@exigoh.com</a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">{t['contact']['location']}</h4>
                                        <p>Goethestr. 64</p>
                                        <p>84032 Landshut</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
export default ContactOne;
