import React, {useRef, useState} from 'react';
import emailjs from 'emailjs-com';
import * as Trans from "../../data/lang/text";

const t = Trans.getText();

const Result = () => {
    setTimeout(() => {
        window.location = "/";
    }, 3000)

    return (
        <p className="success-message">{t['contact']['send']}</p>
    )
}

function FormularContactForm({formStyle, answers}) {
    const [result, showresult] = useState(false);
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(
                'service_oyvik4h',
                'template_nccd9qt',
                form.current,
                'user_Yda6NVQbQtOgAqg5PbFDQ'
            )
            .then((result) => {
                    console.log('SUCCESS!', result.status, result.text);
                },
                (error) => {
                    console.log('FAILED...', error);
                }
            );
        // e.target.reset();
        showresult(true);
    };


    setTimeout(() => {
        showresult(false);
    }, 5000);


    return (
        <form ref={form} className={`${formStyle}`} action="" onSubmit={sendEmail}>
            <div className="form-group">
                <input
                    type="text"
                    name="fullname"
                    placeholder={t['contact']['name']}
                    required
                />
            </div>

            <div className="form-group">
                <input
                    type="email"
                    name="email"
                    placeholder={t['contact']['your_email']}
                    required
                />
            </div>

            <div className="form-group">
                <input
                    type="text"
                    name="website"
                    placeholder={t['contact']['your_website']}
                />
            </div>

            <div className="form-group">
                <input
                    type="text"
                    name="phone"
                    placeholder={t['contact']['your_phone']}
                    required
                />
            </div>

            <div className="form-group">
                <input
                    type="text"
                    name="subject"
                    placeholder={t['contact']['subject']}
                    required
                />
            </div>

            <div className="form-group">
                <textarea
                    name="message"
                    placeholder={t['contact']['message']}
                    required
                >
                </textarea>
            </div>

            <div hidden className="form-group">
                <textarea
                    name="answers"
                    value={
                        answers.map((answer) => (
                                "id: " + answer.id + "\n" + answer.answer + "\n\n"
                            )
                        )
                    }>
            </textarea>
            </div>

            <div className="form-group text-center">
                <button className="btn-default btn-large">{t['nav']['contact_btn']}</button>
            </div>

            <div className="form-group">
                {result ? <Result/> : null}
            </div>
        </form>
    )
}

export default FormularContactForm;
