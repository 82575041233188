import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import * as Trans from "../../data/lang/text";


const SlpitOne = () => {
    const t = Trans.getText();
    return (
            <div className="rn-splite-style">
                <div className="split-wrapper">
                        <div className="row no-gutters radius-10 align-items-center">
                            <div className="col-lg-12 col-xl-6 col-12">
                                <div className="thumbnail image-left-content">
                                    <img src="./images/service/website.svg" />
                                </div>
                            </div>
                            <div className="col-lg-12 col-xl-6 col-12">
                                <div className="split-inner">
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <h4 className="title">{t['website']['s1_1']}</h4>
                                    </ScrollAnimation>
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                    <p className="description">{t['website']['s1_2']}</p>
                                    </ScrollAnimation>


                                    <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <p><b>{t['website']['s1_3'][0]}</b></p>
                                        <ul className="split-list">
                                            <li>- {t['website']['s1_3'][1]}</li>
                                            <li>- {t['website']['s1_3'][2]}</li>
                                            <li>- {t['website']['s1_3'][3]}</li>
                                            <li>- {t['website']['s1_3'][4]}</li>
                                            <li>- {t['website']['s1_3'][5]}</li>
                                        </ul>
                                    </ScrollAnimation>
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <div className="view-more-button mt--35">
                                            <a className="btn-default" href="/contact">{t['website']['s1_4']}</a>
                                        </div>
                                    </ScrollAnimation>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default SlpitOne
