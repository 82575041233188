import React from 'react';
import SEO from "../common/SEO";
import Typed from 'react-typed';
import {Link} from "react-router-dom";
import {FiArrowRight} from "react-icons/fi";
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import ServiceTwo from "../elements/service/ServiceTwo";
import Layout from "../common/Layout";
import * as Trans from "../data/lang/text";

const HomePage = () => {
    const t = Trans.getText();
    return (
        <>
            <SEO title="Digital Agency" desc="EXIGOH - Software Development Company"/>
            <Layout>
                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-overlay bg_image variation-2 height-750"
                     style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/home-bg.jpg)`}}>
                    <div className="container hero-container-z-index">
                        <div className="row">
                            <div className="col-lg-8 order-2 order-lg-1 mt_md--40 mt_sm--40">
                                <div className="inner text-left">
                                    <h1 className="title theme-gradient display-two">Software Development <br/> {" "}
                                        <Typed
                                            strings={[
                                                "Mobile App.",
                                                "Website.",
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                        />
                                    </h1>
                                    <p className="description">{t["home"]["subtitle"]}</p>
                                    <div className="button-group">
                                        <Link className="btn-default btn-medium btn-border round btn-icon"
                                              to="/contact">{t["global"]["contact"]} <i className="icon"><FiArrowRight/></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator/>

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle={t["home"]["s1_1"]}
                                    title={t["home"]["s1_2"]}
                                    description={t["home"]["s1_3"]}
                                />
                            </div>
                        </div>
                        <ServiceTwo
                            cardStyle="card-style-1"
                            textAlign="text-left"
                        />
                    </div>
                </div>

                {/* End Service Area  */}
                {/* <Separator/> */}

                {/*Start Portfolio Area*/}
                {/* <Portfolio/> */}
                {/*End Portfolio Area*/}
            </Layout>
        </>
    )
}

export default HomePage;
